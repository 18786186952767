<template>
    <div>
        <h4>Models</h4>
        <modal id="addModel" title="Ajouter une modèle">
            <form id="add_version" class="form-horizontal">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="nom">Nom</label>
                    <input type="text" class="form-control" name="nom" id="nom" v-model="nom"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="has_client"> <input type="checkbox" name="has_client" id="has_client" v-model="hasClient"/> Has Client?</label>
                    
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="description">Description</label>
                    <textarea
                      name="description"
                      id="description"
                      class="form-control"
                      rows="4"
                      v-model="description"
                    />
                  </div>
                </div>
              </div>
            </form>
            <template slot="footer">
              <a
                href="javascript:void(0)"
                class="btn btn-primary"
                @click="addingModel"
              > Ajouter</a>
            </template>
        </modal>
        <data-table :dataSource="models" :headers="headers">
            <template slot="actions">
              <a href="javascript:void(0)"
                class="btn btn-outline-primary btn-icon"
                @click.prevent="showAddModal"
              >
                <i class="icofont icofont-plus" />
              </a>
            </template>
        </data-table>
    </div>
</template>
<script>
import jQuery from 'jquery'
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../components/dataTable/local.vue'
import Modal from '../../components/modal.vue'
import {COMPONENT_TYPE, TEXT_TYPE} from '../../components/dataTable/dataType'
import {ADD_MODEL} from '../../graphql/host'
const $ = jQuery
const Action = () => import('../../components/host/model/listAction.vue')
export default {
    components: {DataTable, Modal},
    data(){
        return {
            nom: '',
            description: '',
            hasClient: false
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE'
        }),
        showAddModal(){
            $('#addModel').modal('show')
        },
        addingModel(){
            this.$apollo.mutate({
                mutation: ADD_MODEL,
                variables: {
                    "model": {
                        "name": this.nom,
                        "description": this.description,
                        "hasClient": this.hasClient
                    }
                  },
                update: () => {
                    this.nom = null
                    this.description = null
                    this.done()
				},
            })
        }
    },
    computed: {
        ...mapGetters({
            models: 'host/models'
        }),
        headers(){
            return [
                {label: 'Nom', name: 'name', type: TEXT_TYPE, sortable: true},
                {label: 'Has Client', name: 'hasClient', type: TEXT_TYPE, sortable: true},
                {label: 'Description', name: 'description', type: TEXT_TYPE, sortable: true},
                {label: 'Action', type: COMPONENT_TYPE, component: Action}
            ]
        }
    }
}
</script>